import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import * as animationData from "../../animations/calendar.json";
import BlogCard from "../../components/BlogCard";
import { urlFor } from "../../helpers/imageBuilder";
import ApiServices from "../../http/api_services";
import { getDatePicker } from "../../redux/slices/posts";
import { sortRecentPost } from "../../utils/gen";

const Posts = () => {
  const [posts, setPosts] = useState([]);
  const datePicker = useSelector(getDatePicker);
  useEffect(() => {
    ApiServices.fetchPost((response, error) => {
      if (error) {
        return;
      }
      setPosts(() => sortRecentPost(response));
    });
  }, []);

  useEffect(() => {
    if (datePicker) {
      let currentBlogDate = format(new Date(datePicker), "yyyy-MM-dd");
      let nextMonthDate = new Date(currentBlogDate).getMonth() + 1;
      let nextCurrentDate = format(
        new Date(datePicker).setMonth(nextMonthDate),
        "yyyy-MM-dd"
      );
      ApiServices.fetchPostByDate(
        currentBlogDate,
        nextCurrentDate,
        (response, error) => {
          if (error) {
            return;
          }
          setPosts(() => sortRecentPost(response));
        }
      );
    }
  }, [datePicker]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-start flex-row gap-x-3 flex-wrap mt-3 mx-5">
        {posts &&
          posts?.length > 0 &&
          posts?.map((post) => {
            let imageUrl = post?.cover?.asset["_ref"];
            const postSlug = post?.slug ? post?.slug.current : "";
            const postImageDescription = post?.cover
              ? post?.cover["attribution"]
              : "";

            return (
              <>
                <BlogCard
                  key={post?._id}
                  title={post?.title}
                  content={post?.content}
                  slug={postSlug}
                  description={post.description}
                  imageUrl={urlFor(imageUrl)}
                  alt={postImageDescription}
                  dateCreated={post?.created}
                />
              </>
            );
          })}

        {posts && posts?.length === 0 && (
          <div className="not-found__container">
            <Lottie options={defaultOptions} height={200} width={200} />
            <p className="not_found__title font-weight-bold text-center">
              No posts found, friend! Looks like the blog is taking a break and
              enjoying some peace and quiet. Time to grab a cup of coffee and
              relax! <span style={{ fontSize: "25px" }}>☺️</span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Posts;
