import { faBookOpen, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import remarkGfm from "remark-gfm";
import { formatDate, generateWordsPerMinutes } from "../utils/gen";

const BlogCard = (props) => {
  const { content, slug, title, description, dateCreated } = props;

  return (
    <div className="post-preview">
      <Link to={`/blog/${slug}`} className="post-link">
        <h2 className="post-title text-green">{title}</h2>
        <h3 className="post-subtitle">
          <ReactMarkdown children={description} remarkPlugins={[remarkGfm]} />
        </h3>
      </Link>
      <div className="post-footer d-flex justify-content-between align-items-center">
        <div className="post-meta d-flex gap-2 align-items-center">
          <FontAwesomeIcon icon={faClock} />
          <span>{formatDate(dateCreated)}</span>
        </div>
        <div className="post-duration d-flex gap-2 align-items-center">
          <FontAwesomeIcon icon={faBookOpen} />
          <span>{generateWordsPerMinutes(content)}</span>
        </div>
        <hr className="my-4" />
      </div>
    </div>
  );
};

export default BlogCard;
