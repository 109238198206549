import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import NavigationBar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { generateWordsPerMinutes } from "../utils/gen";

const Header = (props) => {
  const { headerImage, title, subTitle, isPost = false, content } = props;
  return (
    <>
      <NavigationBar />
      <header
        className="header"
        style={{ backgroundImage: `url(${headerImage})` }}
      >
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={10} lg={8} xl={7}>
              <div className="page-heading">
                <h1>{title}</h1>
                <span>{subTitle}</span>
              </div>
              {isPost &&
                (content ? (
                  <>
                    <div className="text-center text-white d-flex gap-2 justify-content-center align-items-center post-duration mt-2">
                      <FontAwesomeIcon icon={faBookOpen} />
                      <span>{generateWordsPerMinutes(content)}</span>
                    </div>
                  </>
                ) : (
                  <div></div>
                ))}
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Header;
