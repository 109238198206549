import React from "react";
import Footer from "../../components/Footer";
import { Col, Container, Row } from "react-bootstrap";
import Header from "../../components/Header";

const About = () => {
  return (
    <div>
      <Header
        title={"About Me"}
        subTitle={"This is what I do"}
        headerImage={"/assets/about.jpeg"}
      />
      <Container className="about-container">
        <Row className="justify-content-center">
          <Col md={10} lg={8} xl={7}>
            <p>
              Passionate about the art of software engineering, I have always
              been driven by a deep curiosity for how software is built. This
              innate curiosity led me to pursue a career as a software engineer,
              specializing in frontend, backend, and mobile development.
            </p>

            <p>
              Driven by a strong work ethic and a passion for problem-solving, I
              am dedicated to delivering excellence in every project I
              undertake. I thrive in collaborative environments where I can
              leverage my technical expertise to drive tangible results and make
              a meaningful impact. If you're seeking a skilled and versatile
              software engineer who is committed to creating exceptional
              software solutions, I would love to connect. Let's discuss how I
              can contribute to your organization's success and help you
              overcome your technological challenges.
            </p>

            <p>
              Feel free to reach out and connect with me. I look forward to
              exploring potential opportunities and forging valuable
              professional connections.
            </p>
          </Col>
        </Row>
        <Footer />
      </Container>
    </div>
  );
};

export default About;
